<template>
  <div id="nav">
    <div class="about">
      <div class="intro-text">
        <span class="logo">mindshare</span> is a site that acts a frictionless
        platform for random thoughts, feelings and ideas.
      </div>
    </div>
  </div>

  <router-view v-slot="{ Component }">
    <Transition name="page-opacity" mode="out-in">
      <component :is="Component" />
    </Transition>
  </router-view>
  <div class="home">
    <div class="posts">
      <div class="loading" v-if="loading"></div>
      <div v-if="error" class="error">
        {{ error }}
      </div>
      <div class="container">
        <div class="latest-posts">Latest Posts</div>
        <div v-for="post in posts" class="post-item" :key="post._id">
          <div class="post-container">
            <router-link :to="`/blog/${post.slug.current}`">
              <div class="author-image">
                <img
                  v-if="post.authorImage"
                  :src="imageUrlFor(post.authorImage).width(100)"
                />
              </div>

              <div class="post-details">
                <div class="post-excerpt">
                  <p>{{ post.excerpt }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);

const query = `*[_type == "post"]{
  _id,
  title,
  slug,
  "image": mainImage,
  publishedAt,
  excerpt,
  "name":author->name,
  "authorImage":author->image
}[0...50]`;

export default {
  name: "Home",
  data() {
    return {
      loading: true,
      posts: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },

    fetchData() {
      this.error = this.post = null;
      this.loading = true;
      sanity.fetch(query).then(
        (posts) => {
          this.loading = false;
          this.posts = posts;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>

<style scoped>
.posts {
  float: left;
}

.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0% 0%;
  max-width: 600px;
  height: auto;
  width: 100%;
}

.post-item {
  display: flex;
  width: 100%;
  transition: 0.1s all ease;
  opacity: 1;
}

.post-item:hover > .post-container a {
  opacity: 0.8;
  animation: rainbow-bg 6s infinite;
}

/* .post-item:nth-child(even) {
} */

.post-container a:hover {
}

.post-container a:hover > .author-image {
}

.post-container a:hover > .post-details {
}

/* .post-transform :hover {
  transform: translateX(4rem);
  transition: 0.5s ease all;
} */

.post-alignment {
  display: flex;
}

/* .post-thumbnail {
  width: 100%;
  align-self: center;
  margin-bottom: -1rem;
} */
/* 
.post-thumbnail img {
  border-radius: 0.4rem;
  box-sizing: border-box;
  min-width: 150px;
  max-width: 150px;
} */
</style>
