<template>
  <router-view></router-view>
</template>

<style>
.fadeout-enter-active,
.fadeout-leave-active {
  transition: 600ms ease all;
}
.fadeout-enter-from, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

@font-face {
  font-family: "PicNic";
  src: local("PicNic"), url(./fonts/PicNic/PicNic-Regular.woff) format("woff");
}

#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.25rem;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-height: 100vh;
  padding: 2rem;
  width: 100%;
  margin-bottom: 8rem;
}

#nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  gap: 1rem;
  margin-bottom: 2rem;
}

#nav a {
  color: inherit;
}

#nav a.router-link-exact-active {
  color: inherit;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Helvetica, sans-serif;
  letter-spacing: 0.02rem;
  margin: 0 0 0 0;
}

h1 {
  font-family: "PicNic", sans-serif;
  font-size: 1.2rem;
  text-transform: lowercase;
  line-height: 70%;
  font-weight: 400;
}

h2 {
  font-weight: 800;
  font-size: 0.9rem;
  font-style: oblique;
}

p,
ul,
ol {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

p {
  line-height: 1.5rem;
}

.entry-body p {
  padding-bottom: 1rem;
}

em {
  font-style: normal;
  animation: rainbow-text-bg 10s infinite;
}

a {
  text-decoration: none;
  color: inherit;
  opacity: 1;
  transition: all 0.5s;
}

a:hover {
  opacity: 0.5;
}

img {
  max-width: 100%;
}

hr {
  background-color: #cccccc;
  border: 0;
  height: 1px;
  margin: 40px auto;
}

blockquote {
  border-left: 4px solid #cccccc;
  font-size: 1.4rem;
  font-style: italic;
  margin: 2rrem 0;
  padding-left: 2rrem;
  padding-right: 2rrem;
}

/* shared styles */

.post-container a {
  border-radius: 1rem;
  border: 1px solid #e7e7e7;
  backdrop-filter: blur(10px);
  background: #fff;
  gap: 1rem;
  padding: 0.5rem 01rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  opacity: 1;
  position: relative;
  transform: translateY(0px);
  align-items: center;
  max-width: 450px;
}

.post-date {
  display: block;
  width: max-content;
  margin-bottom: 0rem;
  text-transform: uppercase;
  font-weight: 600;
  opacity: 0.4;
  font-size: 0.8rem;
}

.post-excerpt {
  font-size: 1rem;
  max-width: 100%;
  min-width: min-content;
}

.latest-posts {
  text-transform: uppercase;
  font-weight: 600;
  opacity: 0.4;
  font-size: 0.8rem;
}

.post-details {
  display: flex;
  flex-direction: column;
  transition: all 0.35s ease;
}

.intro-text {
  margin-top: 0rem;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.05rem;
  width: 600px;
  margin-bottom: 2rem;
}

.logo {
  animation: rainbow-text 6s infinite;
}

.button {
  font-size: 01.25rem;
  line-height: 1.5rem;
  border-radius: 5rem;
  padding: 0.6rem 1rem 0.6rem 1rem;
  background-color: rgba(0, 0, 0);
  color: #fff;
  backdrop-filter: blur(10px);
  z-index: 100;
  font-size: 1.25rem;
}
.author-image {
  max-width: 3rem;
  max-height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  padding: 0rem;
  margin: 0;
  line-height: 0;
  transition: all 0.35s ease;
}

.author-image img {
  border-radius: 24rem;
  max-width: 3rem;
  max-height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
}

.dropshadow {
  /* filter: drop-shadow(0 0 0.4rem rgb(205, 205, 205)); */
}

/* transition classes */

.fadeout-enter-active,
.fade-leave-active {
  transition: 600ms ease all;
}

.fadeout-enter-from,
.fadeout-leave-to {
  opacity: 0;
}

@media (max-width: 1020px) {
  h1 {
    font-size: 3rem;
  }

  .content h1 {
    font-size: 2.4rem;
  }
}
/* 
rainbow text */

@keyframes rainbow-text {
  0% {
    color: #e87d7d;
  }
  2% {
    color: #e88a7d;
  }
  4% {
    color: #e8977d;
  }
  6% {
    color: #e8a47d;
  }
  8% {
    color: #e8b07d;
  }
  10% {
    color: #e8bd7d;
  }
  12% {
    color: #e8ca7d;
  }
  14% {
    color: #e8d77d;
  }
  16% {
    color: #e8e47d;
  }
  18% {
    color: #dfe87d;
  }
  20% {
    color: #d3e87d;
  }
  22% {
    color: #c6e87d;
  }
  24% {
    color: #b9e87d;
  }
  26% {
    color: #ace87d;
  }
  28% {
    color: #9fe87d;
  }
  30% {
    color: #92e87d;
  }
  32% {
    color: #86e87d;
  }
  34% {
    color: #7de881;
  }
  36% {
    color: #7de88e;
  }
  38% {
    color: #7de89b;
  }
  40% {
    color: #7de8a8;
  }
  42% {
    color: #7de8b5;
  }
  44% {
    color: #7de8c1;
  }
  46% {
    color: #7de8ce;
  }
  48% {
    color: #7de8db;
  }
  50% {
    color: #7de8e8;
  }
  52% {
    color: #7ddbe8;
  }
  54% {
    color: #7dcee8;
  }
  56% {
    color: #7dc1e8;
  }
  58% {
    color: #7db5e8;
  }
  60% {
    color: #7da8e8;
  }
  62% {
    color: #7d9be8;
  }
  64% {
    color: #7d8ee8;
  }
  66% {
    color: #7d81e8;
  }
  68% {
    color: #867de8;
  }
  70% {
    color: #927de8;
  }
  72% {
    color: #9f7de8;
  }
  74% {
    color: #ac7de8;
  }
  76% {
    color: #b97de8;
  }
  78% {
    color: #c67de8;
  }
  80% {
    color: #d37de8;
  }
  82% {
    color: #df7de8;
  }
  84% {
    color: #e87de4;
  }
  86% {
    color: #e87dd7;
  }
  88% {
    color: #e87dca;
  }
  90% {
    color: #e87dbd;
  }
  92% {
    color: #e87db0;
  }
  94% {
    color: #e87da4;
  }
  96% {
    color: #e87d97;
  }
  98% {
    color: #e87d8a;
  }
  100% {
    color: #e87d7d;
  }
}

@keyframes rainbow-bg {
  0% {
    filter: drop-shadow(0px 0px 2px #e87d7d);
  }
  2% {
    filter: drop-shadow(0px 0px 2px #e88a7d);
  }
  4% {
    filter: drop-shadow(0px 0px 3px #e8977d);
  }
  6% {
    filter: drop-shadow(0px 0px 4px #e8a47d);
  }
  8% {
    filter: drop-shadow(0px 0px 5px #e8b07d);
  }
  10% {
    filter: drop-shadow(0px 0px 6px #e8bd7d);
  }
  12% {
    filter: drop-shadow(0px 0px 7px #e8ca7d);
  }
  14% {
    filter: drop-shadow(0px 0px 8px #e8d77d);
  }
  16% {
    filter: drop-shadow(0px 0px 9px #e8e47d);
  }
  18% {
    filter: drop-shadow(0px 0px 10px #dfe87d);
  }
  20% {
    filter: drop-shadow(0px 0px 9px #d3e87d);
  }
  22% {
    filter: drop-shadow(0px 0px 8px #c6e87d);
  }
  24% {
    filter: drop-shadow(0px 0px 7px #b9e87d);
  }
  26% {
    filter: drop-shadow(0px 0px 6px #ace87d);
  }
  28% {
    filter: drop-shadow(0px 0px 5px #9fe87d);
  }
  30% {
    filter: drop-shadow(0px 0px 4px #92e87d);
  }
  32% {
    filter: drop-shadow(0px 0px 3px #86e87d);
  }
  34% {
    filter: drop-shadow(0px 0px 2px #7de881);
  }
  36% {
    filter: drop-shadow(0px 0px 2px #7de88e);
  }
  38% {
    filter: drop-shadow(0px 0px 3px #7de89b);
  }
  40% {
    filter: drop-shadow(0px 0px 4px #7de8a8);
  }
  42% {
    filter: drop-shadow(0px 0px 5px #7de8b5);
  }
  44% {
    filter: drop-shadow(0px 0px 6px #7de8c1);
  }
  46% {
    filter: drop-shadow(0px 0px 7px #7de8ce);
  }
  48% {
    filter: drop-shadow(0px 0px 8px #7de8db);
  }
  50% {
    filter: drop-shadow(0px 0px 9px #7de8e8);
  }
  52% {
    filter: drop-shadow(0px 0px 10px #7ddbe8);
  }
  54% {
    filter: drop-shadow(0px 0px 9px #7dcee8);
  }
  56% {
    filter: drop-shadow(0px 0px 8px #7dc1e8);
  }
  58% {
    filter: drop-shadow(0px 0px 7px #7db5e8);
  }
  60% {
    filter: drop-shadow(0px 0px 6px #7da8e8);
  }
  62% {
    filter: drop-shadow(0px 0px 5px #7d9be8);
  }
  64% {
    filter: drop-shadow(0px 0px 4px #7d8ee8);
  }
  66% {
    filter: drop-shadow(0px 0px 3px #7d81e8);
  }
  68% {
    filter: drop-shadow(0px 0px 2px #867de8);
  }
  70% {
    filter: drop-shadow(0px 0px 2px #927de8);
  }
  72% {
    filter: drop-shadow(0px 0px 3px #9f7de8);
  }
  74% {
    filter: drop-shadow(0px 0px 4px #ac7de8);
  }
  76% {
    filter: drop-shadow(0px 0px 5px #b97de8);
  }
  78% {
    filter: drop-shadow(0px 0px 6px #c67de8);
  }
  80% {
    filter: drop-shadow(0px 0px 7px #d37de8);
  }
  82% {
    filter: drop-shadow(0px 0px 8px #df7de8);
  }
  84% {
    filter: drop-shadow(0px 0px 9px #e87de4);
  }
  86% {
    filter: drop-shadow(0px 0px 10px #e87dd7);
  }
  88% {
    filter: drop-shadow(0px 0px 9px #e87dca);
  }
  90% {
    filter: drop-shadow(0px 0px 8px #e87dbd);
  }
  92% {
    filter: drop-shadow(0px 0px 7px #e87db0);
  }
  94% {
    filter: drop-shadow(0px 0px 6px #e87da4);
  }
  96% {
    filter: drop-shadow(0px 0px 5px #e87d97);
  }
  98% {
    filter: drop-shadow(0px 0px 3px #e87d8a);
  }
  100% {
    filter: drop-shadow(0px 0px 2px #e87d7d);
  }
}

@keyframes rainbow-text-bg {
  0% {
    filter: drop-shadow(0px 0px 2px #e87d7d);
  }
  2% {
    filter: drop-shadow(0px 0px 2px #e88a7d);
  }
  4% {
    filter: drop-shadow(0px 0px 2px #e8977d);
  }
  6% {
    filter: drop-shadow(0px 0px 2px #e8a47d);
  }
  8% {
    filter: drop-shadow(0px 0px 2px #e8b07d);
  }
  10% {
    filter: drop-shadow(0px 0px 2px #e8bd7d);
  }
  12% {
    filter: drop-shadow(0px 0px 2px #e8ca7d);
  }
  14% {
    filter: drop-shadow(0px 0px 2px #e8d77d);
  }
  16% {
    filter: drop-shadow(0px 0px 2px #e8e47d);
  }
  18% {
    filter: drop-shadow(0px 0px 2px #dfe87d);
  }
  20% {
    filter: drop-shadow(0px 0px 2px #d3e87d);
  }
  22% {
    filter: drop-shadow(0px 0px 2px #c6e87d);
  }
  24% {
    filter: drop-shadow(0px 0px 2px #b9e87d);
  }
  26% {
    filter: drop-shadow(0px 0px 2px #ace87d);
  }
  28% {
    filter: drop-shadow(0px 0px 2px #9fe87d);
  }
  30% {
    filter: drop-shadow(0px 0px 2px #92e87d);
  }
  32% {
    filter: drop-shadow(0px 0px 2px #86e87d);
  }
  34% {
    filter: drop-shadow(0px 0px 2px #7de881);
  }
  36% {
    filter: drop-shadow(0px 0px 2px #7de88e);
  }
  38% {
    filter: drop-shadow(0px 0px 2px #7de89b);
  }
  40% {
    filter: drop-shadow(0px 0px 2px #7de8a8);
  }
  42% {
    filter: drop-shadow(0px 0px 2px #7de8b5);
  }
  44% {
    filter: drop-shadow(0px 0px 2px #7de8c1);
  }
  46% {
    filter: drop-shadow(0px 0px 2px #7de8ce);
  }
  48% {
    filter: drop-shadow(0px 0px 2px #7de8db);
  }
  50% {
    filter: drop-shadow(0px 0px 2px #7de8e8);
  }
  52% {
    filter: drop-shadow(0px 0px 2px #7ddbe8);
  }
  54% {
    filter: drop-shadow(0px 0px 2px #7dcee8);
  }
  56% {
    filter: drop-shadow(0px 0px 2px #7dc1e8);
  }
  58% {
    filter: drop-shadow(0px 0px 2px #7db5e8);
  }
  60% {
    filter: drop-shadow(0px 0px 2px #7da8e8);
  }
  62% {
    filter: drop-shadow(0px 0px 2px #7d9be8);
  }
  64% {
    filter: drop-shadow(0px 0px 2px #7d8ee8);
  }
  66% {
    filter: drop-shadow(0px 0px 2px #7d81e8);
  }
  68% {
    filter: drop-shadow(0px 0px 2px #867de8);
  }
  70% {
    filter: drop-shadow(0px 0px 2px #927de8);
  }
  72% {
    filter: drop-shadow(0px 0px 2px #9f7de8);
  }
  74% {
    filter: drop-shadow(0px 0px 2px #ac7de8);
  }
  76% {
    filter: drop-shadow(0px 0px 2px #b97de8);
  }
  78% {
    filter: drop-shadow(0px 0px 2px #c67de8);
  }
  80% {
    filter: drop-shadow(0px 0px 2px #d37de8);
  }
  82% {
    filter: drop-shadow(0px 0px 2px #df7de8);
  }
  84% {
    filter: drop-shadow(0px 0px 2px #e87de4);
  }
  86% {
    filter: drop-shadow(0px 0px 2px #e87dd7);
  }
  88% {
    filter: drop-shadow(0px 0px 2px #e87dca);
  }
  90% {
    filter: drop-shadow(0px 0px 2px #e87dbd);
  }
  92% {
    filter: drop-shadow(0px 0px 2px #e87db0);
  }
  94% {
    filter: drop-shadow(0px 0px 2px #e87da4);
  }
  96% {
    filter: drop-shadow(0px 0px 2px #e87d97);
  }
  98% {
    filter: drop-shadow(0px 0px 2px #e87d8a);
  }
  100% {
    filter: drop-shadow(0px 0px 2px #e87d7d);
  }
}

@media (max-width: 480px) {
  body {
    font-size: 14px;
  }

  p,
  ul,
  ol {
    font-size: 1.2rem;
    margin: 1rem 0;
  }
}
</style>
